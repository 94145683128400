import React from 'react';

const NavPhone = (props) => {
	return (
		<svg
			viewBox="0 0 10 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M9.54273 0.447934V0.446281C9.26195 0.160785 8.8803 0.000165289 8.48219 0H1.51598C0.688591 0.00272727 0.0185654 0.680455 0.0158691 1.51736V14.481C0.0160326 14.8837 0.174827 15.2697 0.457079 15.5537C0.737451 15.8388 1.11844 15.9994 1.51598 16H8.48382C8.88136 15.9994 9.26235 15.8388 9.54273 15.5537C9.82457 15.2701 9.98333 14.8848 9.98394 14.4826V1.51901C9.98333 1.1169 9.82457 0.731529 9.54273 0.447934ZM4.9999 14.9702C4.77607 14.9702 4.59464 14.7867 4.59464 14.5603C4.59464 14.3339 4.77607 14.1504 4.9999 14.1504C5.22374 14.1504 5.40516 14.3339 5.40516 14.5603C5.40516 14.7867 5.22374 14.9702 4.9999 14.9702ZM8.79267 12.3967C8.79267 12.9444 8.35371 13.3884 7.81221 13.3884H2.18597C1.64446 13.3884 1.2055 12.9444 1.2055 12.3967V2.73554C1.2055 2.18781 1.64446 1.7438 2.18597 1.7438H7.81384C8.35534 1.7438 8.79431 2.18781 8.79431 2.73554L8.79267 12.3967Z"
				fill="black"
			/>
		</svg>
	);
};

export default NavPhone;
