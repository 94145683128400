import React from 'react';

const LogoSvg = (props) => {
	return (
		<svg viewBox="0 0 30 30" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 3.88396H10.7901C13.105 3.83899 15.3926 4.39167 17.4323 5.4888C19.3311 6.53849 20.8829 8.12 21.8975 10.0396C22.4401 11.0526 22.8437 12.1344 23.0971 13.2556V9.77897H29.273V30H23.0971V20.6252C22.8439 21.7465 22.4404 22.8283 21.8975 23.8412C20.8752 25.7648 19.3131 27.3467 17.4038 28.392C15.364 29.4891 13.0765 30.0418 10.7615 29.9968H0V3.88396ZM26.1882 7.53855C26.6959 7.55943 27.2022 7.47221 27.6738 7.28267C28.1453 7.09313 28.5714 6.80557 28.9238 6.43899C29.6252 5.68698 29.9997 4.68676 29.9647 3.65832C29.9818 3.17203 29.8981 2.68746 29.719 2.23515C29.5398 1.78283 29.2691 1.37257 28.9238 1.03019C28.5605 0.683218 28.1308 0.413415 27.6607 0.237015C27.1906 0.060614 26.6897 -0.0187422 26.1882 0.00372423C25.6826 -0.0171502 25.1783 0.0682048 24.7077 0.254297C24.2371 0.440389 23.8106 0.723102 23.4557 1.08422C22.7843 1.8175 22.4117 2.77623 22.4117 3.77114C22.4117 4.76604 22.7843 5.72478 23.4557 6.45806C23.8106 6.81917 24.2371 7.10189 24.7077 7.28798C25.1783 7.47407 25.6826 7.55943 26.1882 7.53855ZM10.5013 24.105C12.4562 24.105 14.025 23.4694 15.2077 22.1982C16.3904 20.9271 16.9817 19.1739 16.9817 16.9388C16.9817 14.6994 16.3904 12.9452 15.2077 11.6762C14.025 10.4071 12.4562 9.77156 10.5013 9.76944H6.4328V24.0954L10.5013 24.105Z"
				fill="white"
			/>
		</svg>
	);
};

export default LogoSvg;
